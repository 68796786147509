@keyframes fadeIn {
    0% {
        opacity: 0.6;
    }

    20% {
        opacity: 0.6;
    }

    40% {
        opacity: 0.7;
    }

    60% {
        opacity: 0.8;
    }

    80% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
    }
}

@keyframes form-control-error-text {
    0% {
        opacity: 0.1;
        transform: translateY(-5px);
    }

    70% {
        opacity: 0.5;
        transform: translateY(0px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slide-down {
    0% {
        opacity: 0.5;
        transform: translateY(-20vh);
    }

    70% {
        opacity: 0.8;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slide-from-right {
    0% {
        opacity: 0.5;
        // transform: translateX(-10px);
    }

    50% {
        opacity: 0.9;
        // transform: translateX(5px);
    }

    100% {
        opacity: 1;
        // transform: translateX(0px);
    }
}


.fade-in-animated {
    animation-name: fadeIn;
    animation-duration: 1.5s;
    animation-timing-function: ease-in;
}

@keyframes menu-slide-down {
    0% {
        opacity: 0.9;
        transform: translateY(90%);
    }

    100% {
        opacity: 1;
        transform: translateY(100%);
    }
}

@keyframes loading-dots-animation {
    0% {
        text-shadow: -1em 0 0 rgb(12 33 72 / 60%), 0em 0 0 rgb(12 33 72 / 20%), 1em 0 0 rgb(12 33 72 / 20%);
    }

    20% {
        text-shadow: -1em 0 0 rgb(12 33 72), 0em 0 0 rgb(12 33 72 / 60%), 1em 0 0 rgb(12 33 72 / 20%);
    }

    40% {
        text-shadow: -1em 0 0 rgb(12 33 72 / 60%), 0em 0 0 rgb(12 33 72), 1em 0 0 rgb(12 33 72 / 60%);
    }

    60% {
        text-shadow: -1em 0 0 rgb(12 33 72 / 20%), 0em 0 0 rgb(12 33 72 / 60%), 1em 0 0 rgb(12 33 72);
    }

    80% {
        text-shadow: -1em 0 0 rgb(12 33 72 / 20%), 0em 0 0 rgb(12 33 72 / 20%), 1em 0 0 rgb(12 33 72 / 60%);
    }

    100% {
        text-shadow: -1em 0 0 rgb(12 33 72 / 60%), 0em 0 0 rgb(12 33 72 / 20%), 1em 0 0 rgb(12 33 72 / 20%);
    }
}

@keyframes left-tree-slide-down {
    0% {
        opacity: 0.0;
        transform: translateY(-10px);
    }

    70% {
        opacity: 0.5;
        transform: translateY(1px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}