.table {

  .table-header {
    ul {
      display: flex;
      align-items: baseline;


      li {
        margin: 1px;

        &.separator {
          flex: 1;
        }
      }
    }
  }

  .table-body {
    border-radius: 10px;

    // th:last-child {
    //   text-align: right;
    //   display: flex;
    //   justify-content: end;
    //   overflow: visible;
    //   background-color: red;
    // }

    table {
      padding: 0px;
      border-radius: 10px;
    }

    tr {
      height: 4.2rem;

      td {
        color: rgb(37, 38, 38);
        font-size: 1.4rem;
      }

      th,
      td {
        border-bottom: none;
        font-family: "Open Sans", OpenSansFallback, sans-serif;
        // font-weight: 400;
      }

      th {

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        // &:last-child {
        //   text-align: right;
        //   display: flex;
        //   justify-content: flex-end;
        //   overflow: visible;
        //   //background-color: red;
        // }
      }


      // th:last-child {
      //   text-align: right;
      //   display: flex;
      //   justify-content: flex-end;
      //   background-color: red;
      // }

      td:last-child {
        text-align: right;
        overflow: visible;

        &>ul {
          display: flex;
          justify-content: flex-end;
        }

        button {
          height: 28px;
          background-color: white;
          color: #252626; // rgb(255, 255, 255);
          //border: solid 1px #8A8A8A; //rgb(255, 255, 255);
        }
      }
    }

    tbody {
      tr:hover {
        cursor: pointer;
        background-color: #dceefa;

        // button {
        //   //background-color: red;
        //   color: rgb(128 128 128);
        //   border: solid 1px rgb(128 128 128 / 78%);
        // }
      }
    }
  }

  .table-footer {
    background-color: rgb(255 255 255);
    border-top: 1px solid #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 0 2rem;
    margin: 0;
  }
}

// .table-body {
//   border-radius: 10px;

//   th:last-child {
//     text-align: right;
//     display: flex;
//     justify-content: end;
//     overflow: visible;
//     background-color: red;
//   }
// }