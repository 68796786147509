.form {
  display: flex;
  flex-direction: column;

  .form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.4rem;

    label {
      font-weight: 500;
    }
    
    .form-control {
      display: flex;
      flex-direction: column;
    }

    .file-form-control {
      @extend .form-control;
      flex-direction: row;

      span {
        flex: 1;
        background-color: #ccc;
        padding: 5px 4px;
        font-size: 1.4rem;
      }

      button {
        margin-left: 5px;
        padding: 5px;
      }
    }
  }

  mat-select {
    border: solid 1px #ccc;
    padding: 3px;
  }

  form {
    display: flex;
    flex: 1;
    flex-direction: column;

    label {
      color: #252626;
      font-weight: 500;
    }

    .required-field-marker {
      color: red;
    }

    .form-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.4rem;
      gap: 0.4rem;
    }

    .form-field-error-text {
      font-size: 1.12rem;
      font-style: italic;
      color: red;
      animation: form-control-error-text ease-out 1s;
    }
  }

  .form-footer {
    display: flex;
    justify-content: flex-end;
  }

  .form-control select[disabled],
  .form-control input[disabled] {
    background-color: #26375821 !important;
    color: #38393c !important;

    option {
      color: #38393c !important;
    }
  }
}