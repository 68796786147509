* {
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", OpenSansFallback, sans-serif;
}

html,
body {
  font-size: 10px;
  background-color: #F7F7F7;
  scrollbar-width: thin;
}

app-root {
  font-size: 14px;
}

label {
  font-size: 1.4rem;
}

.busy-cursor {
  cursor: wait !important;
}

.list-items {
  padding: 20px;
}

.panel-header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 16px;
}

.btn.btn-icon {
  padding: 0;
  border: none;
  cursor: pointer;
}
.btn.btn-icon .vismaicon::before {
  background-color: rgb(73, 74, 74);
}

.btn.btn-icon:disabled {
  cursor: not-allowed;
}
.btn.btn-icon:disabled .vismaicon::before {
  background-color: #8A8A8A;
}

.details-table {
  margin-bottom: 16px;
}
.details-table td {
  padding: 2px 6px;
}
.details-table .label, .details-table .value, .details-table select {
  font-size: 1.4rem !important;
}

.nowrap {
  white-space: nowrap;
}

.pt-16 {
  padding-top: 16px !important;
}

.list-items tr:nth-child(odd) {
  background-color: #F7F7F7;
}

.list-items th {
  background-color: white !important;
  font-size: 14px;
}

#templateVersions tr:nth-child(odd) {
  background-color: #fff;
}
#templateVersions tr:nth-child(odd):hover {
  background-color: #dceefa;
}
#templateVersions tr:first-child {
  background-color: #F7F7F7;
}

.centered {
  text-align: center !important;
}

.centered .mat-sort-header-container {
  justify-content: center;
}

.full-width {
  width: 100%;
}

.vertical-middle {
  vertical-align: middle;
}

.hide-ribbonbar #ribbonbar .tabs {
  display: none;
}

.loaded #ribbonbar .tabs {
  display: block !important;
}

tr.mat-row:hover {
  background-color: yellow !important;
}

ul {
  list-style: none;
}

.paginator {
  display: flex;
  justify-content: center;
}

.hidden {
  visibility: hidden;
}

.displaynone {
  display: none !important;
}

.highlight {
  background-color: #FaD7A2 !important;
  transition: background-color 1s ease;
}

.mt-16 {
  margin-top: 16px;
}

.progressScreen {
  background-color: rgba(237, 229, 229, 0.6588235294);
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4.2rem;
  transition: opacity linear 10s;
  backdrop-filter: blur(8px);
}
.progressScreen p {
  font-size: 1.4rem;
  text-align: center;
  transition: opacity 10s linear;
  animation: 1s ease-out sun-rise;
}
@keyframes sun-rise {
  from {
    /* pushes the sun down past the viewport */
    opacity: 0;
  }
  to {
    /* returns the sun to its default position */
    opacity: 1;
  }
}

.primary-button {
  background-color: #2D7048;
  border-radius: 7px;
}

.wizard {
  flex: 1;
  overflow: hidden;
}
.wizard .step-base, .wizard .delete-step, .wizard .summary-step, .wizard .confirmation-step, .wizard .step {
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: slide-from-right 1.5s ease-out;
}
.wizard .step-base .title, .wizard .delete-step .title, .wizard .summary-step .title, .wizard .confirmation-step .title, .wizard .step .title {
  margin-bottom: 2.8rem;
}
.wizard .step-base .title p, .wizard .delete-step .title p, .wizard .summary-step .title p, .wizard .confirmation-step .title p, .wizard .step .title p {
  white-space: nowrap;
}
.wizard .step-base .title p:last-of-type, .wizard .delete-step .title p:last-of-type, .wizard .summary-step .title p:last-of-type, .wizard .confirmation-step .title p:last-of-type, .wizard .step .title p:last-of-type {
  font-size: 1.3rem;
  color: #64727c;
}
.wizard .step-base .title p:first-of-type, .wizard .delete-step .title p:first-of-type, .wizard .summary-step .title p:first-of-type, .wizard .confirmation-step .title p:first-of-type, .wizard .step .title p:first-of-type {
  font-size: 1.4rem;
  color: #252626;
  font-weight: 700;
}
.wizard .step-base .body, .wizard .delete-step .body, .wizard .summary-step .body, .wizard .confirmation-step .body, .wizard .step .body {
  flex: 1;
}
.wizard .step-base .footer, .wizard .delete-step .footer, .wizard .summary-step .footer, .wizard .confirmation-step .footer, .wizard .step .footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.4rem;
}
.wizard .step-base .footer button, .wizard .delete-step .footer button, .wizard .summary-step .footer button, .wizard .confirmation-step .footer button, .wizard .step .footer button {
  margin-left: 14px;
}
.wizard .confirmation-step .body {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.wizard .confirmation-step .messages {
  flex: 1;
  position: relative;
}
.wizard .confirmation-step .messages .success-message,
.wizard .confirmation-step .messages .error-message {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  padding: 0px;
  background-color: transparent;
  white-space: nowrap;
}
.wizard .confirmation-step .messages .error-message {
  color: rgba(246, 63, 6, 0.7607843137);
}
.wizard .confirmation-step .messages .success-message {
  color: rgba(77, 132, 0, 0.8196078431);
}
.wizard .summary-step dl dt {
  color: #72777a;
}
.wizard .summary-step dl dd {
  background-color: rgba(38, 55, 88, 0.1294117647) !important;
  padding: 5px 5px;
  margin-bottom: 1.4rem;
}
.wizard .summary-step table td {
  padding: 0.7rem 1.4rem;
}
.wizard .summary-step table td:first-of-type {
  background-color: rgba(186, 193, 198, 0.3294117647);
  font-weight: 500;
  color: #778090;
}
.wizard .summary-step table td:last-of-type {
  background-color: rgba(0, 122, 202, 0.2117647059);
}
.wizard .summary-step table dd {
  background-color: rgba(100, 106, 118, 0.2705882353) !important;
  padding: 5px 5px;
  margin-bottom: 1.4rem;
}
.wizard .delete-step .title p:first-of-type {
  color: #ff5722;
}
.wizard .delete-step dl dt {
  color: #72777a;
}
.wizard .delete-step dl dd {
  background-color: rgba(38, 55, 88, 0.1294117647) !important;
  padding: 5px 5px;
  margin-bottom: 1.4rem;
}
.wizard .placeholder-step {
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

mat-dialog-container {
  padding: 0px !important;
}

.user-messages {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}
.user-messages .message-base, .user-messages .success-message, .user-messages .warning-message, .user-messages .error-message, .user-messages .placeholder {
  padding: 1.4rem 0.7rem;
  border-radius: 4px;
}
.user-messages .placeholder {
  visibility: hidden;
}
.user-messages .error-message {
  background-color: rgba(243, 58, 36, 0.1019607843);
  font-weight: 600;
  color: #f50303;
}
.user-messages .warning-message {
  background-color: rgba(255, 136, 0, 0.568627451);
}
.user-messages .success-message {
  color: ghostwhite;
  background-color: rgba(77, 132, 0, 0.8196078431);
}

input,
select,
textarea,
option {
  border: solid 1px #b1b7c4;
  padding: 3px;
  font-size: 1.4rem;
}

select {
  padding: 2px 0rem;
  outline: none;
}

.ribbon-group .ribbon-button-label-text {
  font-size: 12px;
}

mat-icon {
  font-family: "Material Icons" !important;
}
mat-icon.button, mat-icon.button.row-button, mat-icon.row-button.primary-button, mat-icon.row-button.optional-button, mat-icon.row-button.anchor-button, mat-icon.row-button.table-header-button, mat-icon.row-button.toolbar-button, mat-icon.row-button.toolbar-primary-button, mat-icon.row-button.toolbar-optional-button, mat-icon.button.default-button, mat-icon.default-button.primary-button, mat-icon.default-button.optional-button, mat-icon.default-button.anchor-button, mat-icon.default-button.table-header-button, mat-icon.default-button.toolbar-button, mat-icon.default-button.toolbar-primary-button, mat-icon.default-button.toolbar-optional-button, mat-icon.toolbar-button, mat-icon.toolbar-optional-button, mat-icon.toolbar-primary-button, mat-icon.table-header-button, mat-icon.anchor-button, mat-icon.optional-button, mat-icon.primary-button {
  background-color: transparent;
}
mat-icon.button:hover, mat-icon.toolbar-button:hover, mat-icon.toolbar-optional-button:hover, mat-icon.toolbar-primary-button:hover, mat-icon.table-header-button:hover, mat-icon.anchor-button:hover, mat-icon.optional-button:hover, mat-icon.primary-button:hover {
  cursor: pointer;
}
mat-icon.clear {
  color: black;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.large-checkbox {
  width: 19px;
  height: 19px;
  position: relative;
  top: 2px;
}

button {
  font-size: 1.4rem;
  border: 1px solid #c1c3c5;
  padding: 0 2.8rem;
  white-space: nowrap;
  height: 3.2rem;
}
button span {
  color: rgb(136, 135, 135);
  font-size: 1.12rem;
}
button:hover span {
  color: black;
  transition: color ease 1s;
}
button.primary {
  background-color: #4d8400;
  color: #eee;
  box-shadow: 1px 1px 5px 3px transparent;
  border-color: #4d8400;
}
button.primary:hover {
  color: white;
  border-color: #68a611;
  background-color: #68a611;
  box-shadow: 1px 1px 5px 3px rgba(104, 166, 17, 0.2);
  transition: background-color ease 1s;
}
button.mat-button {
  border: 1px solid #c1c3c5;
  box-shadow: 0px 2px 2px rgba(22, 62, 89, 0.12);
  background-color: rgb(77, 132, 0);
  box-shadow: none;
  min-width: 100px;
  color: white;
  font-size: 16px;
}
button.mat-button:hover {
  background-color: rgb(144, 121, 148);
  box-shadow: 1px 1px 5px 3px rgba(104, 166, 17, 0.2);
}
button.has-icon {
  display: flex;
  align-items: center;
}
button[disabled] {
  color: rgba(0, 0, 0, 0.4196078431);
  cursor: default;
  background-color: #e7eae2;
  border: none;
}
button[disabled]:hover {
  color: rgba(0, 0, 0, 0.4196078431);
  background-color: #e7eae2;
  box-shadow: none;
  border: none;
}

.error-text {
  color: rgba(246, 63, 6, 0.7607843137);
}

.empty-table {
  background-color: white;
  text-align: center;
  height: 80px;
  line-height: 80px;
  margin-bottom: 1.4rem;
  color: #263758;
  display: hidden;
}

.primary-button {
  color: white;
  box-shadow: 1px 1px 5px 3px transparent;
}
.primary-button span {
  color: white;
}
.primary-button:hover {
  border-color: #68a611;
  background-color: #68a611;
  box-shadow: 1px 1px 5px 3px rgba(104, 166, 17, 0.2);
  transition: background-color ease 1s;
}
.primary-button:hover span {
  color: white;
}

.optional-button {
  background-color: whitesmoke;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px transparent;
  border-radius: 7px;
}
.optional-button:hover {
  color: #252626;
  border-color: #1482CC;
  background-color: #D2EAFA;
}

.anchor-button {
  color: #7a798b;
  background-color: transparent;
  border: none;
  text-decoration-color: transparent;
}
.anchor-button:hover {
  color: #263758;
  text-decoration: underline;
  text-decoration-color: #263758;
  transition: color ease-out 1s, text-decoration-color ease-out 1s;
}

.table-header-button {
  padding: 0.7rem 0.7rem;
  background-color: #4d8400;
  color: white;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
.table-header-button span {
  color: inherit;
}
.table-header-button mat-icon {
  height: 1.4rem;
  font-size: 1.4rem;
  line-height: 1.4rem;
}
.table-header-button:hover {
  color: rgba(0, 96, 161, 0.9019607843);
  background-color: #68a611;
  transition: background-color ease 1s;
}
.table-header-button:hover span,
.table-header-button:hover mat-icon {
  color: white;
}

.close-button {
  position: absolute !important;
  right: 0px;
  top: -3px;
}
.close-button button {
  border: none;
  font-size: 2rem;
  line-height: 2rem;
  color: #252626;
  background: transparent;
}
.close-button:hover {
  cursor: pointer;
}
.close-button:hover button {
  color: #263758;
}

.toolbar-button, .toolbar-optional-button, .toolbar-primary-button {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  margin-left: 5px;
}
.toolbar-button mat-icon, .toolbar-optional-button mat-icon, .toolbar-primary-button mat-icon {
  font-size: 1.2rem;
  width: auto;
  height: auto;
}

.toolbar-primary-button {
  background-color: rgb(211, 206, 206);
}
.toolbar-primary-button mat-icon,
.toolbar-primary-button span {
  color: white;
}
.toolbar-primary-button mat-icon {
  font-size: 1.4rem;
  padding-right: 3px;
}
.toolbar-primary-button:hover {
  background-color: #68a611;
}
.toolbar-primary-button:hover mat-icon,
.toolbar-primary-button:hover span {
  color: white;
}

.toolbar-optional-button {
  background-color: whitesmoke;
}
.toolbar-optional-button mat-icon,
.toolbar-optional-button span {
  color: #7e8289;
}
.toolbar-optional-button mat-icon {
  font-size: 1.4rem;
  padding-right: 3px;
}
.toolbar-optional-button:hover {
  background-color: #68a611;
}
.toolbar-optional-button:hover mat-icon,
.toolbar-optional-button:hover span {
  color: white;
}

.simple-row-optional-button {
  padding: 6px 25px;
  font-size: 1.4rem;
  background-color: rgba(224, 228, 231, 0.2784313725);
  color: #222b3c;
  border-color: rgba(12, 33, 72, 0.1411764706);
}
.simple-row-optional-button:hover {
  background-color: #007aca;
  color: white;
}

.button:hover, .primary-button:hover, .optional-button:hover, .anchor-button:hover, .table-header-button:hover, .toolbar-button:hover, .toolbar-primary-button:hover, .toolbar-optional-button:hover {
  cursor: pointer;
}
.button.default-button, .default-button.primary-button, .default-button.optional-button, .default-button.anchor-button, .default-button.table-header-button, .default-button.toolbar-button, .default-button.toolbar-primary-button, .default-button.toolbar-optional-button {
  background-color: green;
}
.button.row-button, .row-button.primary-button, .row-button.optional-button, .row-button.anchor-button, .row-button.table-header-button, .row-button.toolbar-button, .row-button.toolbar-primary-button, .row-button.toolbar-optional-button {
  width: 10vw;
}

.dropDown {
  border-radius: 5px;
  width: 100%;
}

.dropDown:hover {
  cursor: pointer;
}

.label {
  margin-right: 20px;
}

.editButton {
  border-radius: 5px;
  padding: 0.42rem 2.8rem;
  background-color: white;
  z-index: 999;
}

.editButton:hover {
  cursor: pointer;
  border-color: #1482CC;
  background-color: #D2EAFA;
}

.editButton3 {
  border-radius: 5px;
  padding: 0.42rem 2.8rem;
  color: white;
  background-color: #2D7048;
}

.editButton3:hover {
  cursor: pointer;
  background-color: #316245;
}

.baseTop {
  padding-bottom: 50px;
}

.list-items th {
  font-weight: bold;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #007ACA !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #007ACA !important;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: #007ACA !important;
  --mdc-checkbox-selected-hover-icon-color: #007ACA !important;
  --mdc-checkbox-selected-icon-color: #007ACA !important;
}

.helpText {
  font-size: 1.7rem;
}

.closeHelp {
  float: right;
}

#butt {
  float: right;
}

#BackgroundDiv > div > div.txui-dialog-titlebar.txui-widget-header.txui-corner-all.txui-helper-clearfix {
  text-align: center;
  margin-left: 5px;
  background-color: #fbfbfb;
}

#OfficelinkModalContent {
  background-color: #fbfbfb;
  padding: 5px 5px 5px 5px;
  border: 1px solid #dddddd;
  border-top: none;
}

#selectedMergeField input,
#selectedMergeField select,
#selectedMergeField textarea,
#selectedMergeField option,
#selectedMergeFieldFormat input,
#selectedMergeFieldFormat select,
#selectedMergeFieldFormat textarea,
#selectedMergeFieldFormat option {
  border: none;
}

#OfficelinkModalContent > div.txui-dialog-buttonpane.txui-widget-content.txui-helper-clearfix > div {
  border-top: none;
}

#mergeFieldDialog {
  width: auto;
  min-height: 61px;
  max-height: none;
  height: auto;
  background-color: #f0f0f0;
  padding: 10px 25px 10px 25px;
  border: none;
}

div.txui-dialog-content fieldset {
  border: 1px solid #dddddd !important;
}

.txui-widget-content {
  background-color: #fbfbfb;
  border: none !important;
}

#modalOkButton #modalCancelButton {
  float: right;
}

.txui-widget-header {
  border-width: 0 !important;
}

#OfficelinkModalContent > div.txui-dialog-buttonpane.txui-widget-content.txui-helper-clearfix > div {
  background-color: #f0f0f0 !important;
  padding: 0 23px 10px 550px;
}

.mat-sort-header-content {
  font-weight: bold;
}

table > thead > tr {
  font-weight: bold;
}

.toolbar {
  box-shadow: rgba(22, 62, 89, 0.0784313725) 0 4px 6px;
  z-index: 1000;
  width: 100%;
}

.center-panel {
  box-shadow: rgba(37, 38, 38, 0.12) 0px 0.1rem 0.1rem 0px;
  z-index: 1000;
}

.mergeFunctionDescription {
  display: flex;
}

.mergeFunctionLabels > p {
  padding-right: 4.2rem;
  padding-bottom: 1.4rem;
  font-weight: bold;
}

.mergeFunctionDescriptionValues p {
  padding-bottom: 1rem;
}

.huidigieVersie {
  font-weight: bold;
  font-size: 1.4rem;
}

.redirectBtn {
  font-size: 1.5rem;
  border: none;
  background-color: #F7F7F7;
  padding: 0;
  color: #007ACA;
}

.redirectBtn:hover {
  cursor: pointer;
  text-decoration: underline;
}

.redirectBtn2 {
  font-size: 1.5rem;
  border: none;
  background-color: #F7F7F7;
  padding: 0;
  color: black;
}

div.txui-dialog-content td,
div.txui-dialog-content th {
  background-color: #f0f0f0;
}

.vismaicon:not(.vismaicon-filled)::before {
  background-color: #8A8A8A;
}

.bread-crumb {
  font-size: 1.5rem;
  margin-bottom: 12px;
}

.dialog-close-button {
  width: 32px;
  height: 32px;
  margin-top: 8px;
}

td.text-max-100 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

.flex-align-end {
  display: flex;
  justify-content: flex-end;
}

.width-42 {
  width: 42px;
}

.width-130 {
  width: 130px;
}

.width-200 {
  width: 200px;
}

.default-layout {
  width: 100%;
  height: 100%;
}
.default-layout div.content {
  width: 100%;
}
.default-layout div.content div.right-column .right-content-container {
  min-width: 580px;
  padding: 20px;
}
.default-layout div.content div.right-column .page-toolbar {
  padding: 1.4rem 0rem;
  display: flex;
  justify-content: flex-start;
  color: #116FAE;
  font-size: 2rem;
}
.default-layout div.content div.right-column .page-toolbar button {
  background-color: #4D8400;
  color: white;
  border: none;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  border-radius: 5px;
}
.default-layout div.content div.right-column .page-toolbar button:hover {
  cursor: pointer;
  background-color: #68A611;
}

.login-layout {
  width: 100vw;
  height: 100vh;
}
.login-layout .content {
  width: 30vw;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 1.4rem;
}
.login-layout .content input {
  margin-bottom: 20px;
  border: none;
  padding: 5px;
}
.login-layout .content header {
  background-color: #CCBEBE;
  padding: 0.7rem 1.4rem;
}
.login-layout .content main {
  display: flex;
  flex-direction: column;
  background-color: #CCBEBE;
  padding: 2.8rem 2.8rem;
  /*         button {
            background-color: #907994;
            border: none;
            padding: 10px;
            width: 5vw;
            color: white;
            margin-left: auto;
          } */
}

@media (max-width: 480px) {
  .login-layout .content {
    width: 50vw;
    background-color: red !important;
  }
}
.layout, .dialog-layout {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 4.2rem;
}

.tm-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 24px !important;
}

.dialog-layout {
  padding: 0px;
  position: relative;
}
.dialog-layout .left-panel,
.dialog-layout .right-panel {
  display: flex;
  flex-direction: column;
  padding: 4.2rem 4.2rem 4.2rem 4.2rem;
}
.dialog-layout .left-panel {
  background-color: #007aca;
  justify-content: center;
}
.dialog-layout .left-panel p {
  color: white;
}
.dialog-layout .left-panel .main-text {
  margin-top: auto;
  margin-bottom: auto;
}
.dialog-layout .left-panel .main-text p {
  font-size: 4.2rem;
}
.dialog-layout .left-panel .footer-text p {
  font-size: 1.12rem;
  max-width: 10vw;
}
.dialog-layout .right-panel {
  flex: 1;
}
.dialog-layout .right-panel .close-button button {
  position: absolute;
  top: 0px;
  right: 5px;
  padding: 0px;
  cursor: pointer;
}
.dialog-layout .right-panel form {
  flex: 1;
  display: flex;
}
.dialog-layout .right-panel .overview-table thead tr td {
  background-color: #007aca;
  color: white;
  font-weight: 400;
}
.dialog-layout .right-panel .overview-table tbody td {
  padding: 0.35rem 1.4rem;
  background-color: rgba(147, 163, 217, 0.1803921569);
  color: #263758;
  font-weight: 300;
}
.dialog-layout .right-panel .overview-table tbody td button {
  padding: 5px 15px;
}
.dialog-layout .right-panel .overview-table tbody td:first-of-type {
  font-weight: 600;
  background-color: rgba(165, 166, 177, 0.2392156863);
}
.dialog-layout .right-panel .overview-table tbody td:last-of-type button {
  width: 100%;
  color: rgba(38, 55, 88, 0.7215686275);
}
.dialog-layout .right-panel .overview-table tbody td:last-of-type button:hover {
  background-color: #4d8400;
  color: white;
}

.form {
  display: flex;
  flex-direction: column;
}
.form .form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
}
.form .form-row label {
  font-weight: 500;
}
.form .form-row .form-control, .form .form-row .file-form-control {
  display: flex;
  flex-direction: column;
}
.form .form-row .file-form-control {
  flex-direction: row;
}
.form .form-row .file-form-control span {
  flex: 1;
  background-color: #ccc;
  padding: 5px 4px;
  font-size: 1.4rem;
}
.form .form-row .file-form-control button {
  margin-left: 5px;
  padding: 5px;
}
.form mat-select {
  border: solid 1px #ccc;
  padding: 3px;
}
.form form {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.form form label {
  color: #252626;
  font-weight: 500;
}
.form form .required-field-marker {
  color: red;
}
.form form .form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
  gap: 0.4rem;
}
.form form .form-field-error-text {
  font-size: 1.12rem;
  font-style: italic;
  color: red;
  animation: form-control-error-text ease-out 1s;
}
.form .form-footer {
  display: flex;
  justify-content: flex-end;
}
.form .form-control select[disabled], .form .form-row .file-form-control select[disabled],
.form .form-control input[disabled],
.form .form-row .file-form-control input[disabled] {
  background-color: rgba(38, 55, 88, 0.1294117647) !important;
  color: #38393c !important;
}
.form .form-control select[disabled] option, .form .form-row .file-form-control select[disabled] option,
.form .form-control input[disabled] option,
.form .form-row .file-form-control input[disabled] option {
  color: #38393c !important;
}

.error-layout div {
  background-color: yellow;
}

@keyframes fadeIn {
  0% {
    opacity: 0.6;
  }
  20% {
    opacity: 0.6;
  }
  40% {
    opacity: 0.7;
  }
  60% {
    opacity: 0.8;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
@keyframes form-control-error-text {
  0% {
    opacity: 0.1;
    transform: translateY(-5px);
  }
  70% {
    opacity: 0.5;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-20vh);
  }
  70% {
    opacity: 0.8;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes slide-from-right {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
.fade-in-animated {
  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

@keyframes menu-slide-down {
  0% {
    opacity: 0.9;
    transform: translateY(90%);
  }
  100% {
    opacity: 1;
    transform: translateY(100%);
  }
}
@keyframes loading-dots-animation {
  0% {
    text-shadow: -1em 0 0 rgba(12, 33, 72, 0.6), 0em 0 0 rgba(12, 33, 72, 0.2), 1em 0 0 rgba(12, 33, 72, 0.2);
  }
  20% {
    text-shadow: -1em 0 0 rgb(12, 33, 72), 0em 0 0 rgba(12, 33, 72, 0.6), 1em 0 0 rgba(12, 33, 72, 0.2);
  }
  40% {
    text-shadow: -1em 0 0 rgba(12, 33, 72, 0.6), 0em 0 0 rgb(12, 33, 72), 1em 0 0 rgba(12, 33, 72, 0.6);
  }
  60% {
    text-shadow: -1em 0 0 rgba(12, 33, 72, 0.2), 0em 0 0 rgba(12, 33, 72, 0.6), 1em 0 0 rgb(12, 33, 72);
  }
  80% {
    text-shadow: -1em 0 0 rgba(12, 33, 72, 0.2), 0em 0 0 rgba(12, 33, 72, 0.2), 1em 0 0 rgba(12, 33, 72, 0.6);
  }
  100% {
    text-shadow: -1em 0 0 rgba(12, 33, 72, 0.6), 0em 0 0 rgba(12, 33, 72, 0.2), 1em 0 0 rgba(12, 33, 72, 0.2);
  }
}
@keyframes left-tree-slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  70% {
    opacity: 0.5;
    transform: translateY(1px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.table .table-header ul {
  display: flex;
  align-items: baseline;
}
.table .table-header ul li {
  margin: 1px;
}
.table .table-header ul li.separator {
  flex: 1;
}
.table .table-body {
  border-radius: 10px;
}
.table .table-body table {
  padding: 0px;
  border-radius: 10px;
}
.table .table-body tr {
  height: 4.2rem;
}
.table .table-body tr td {
  color: rgb(37, 38, 38);
  font-size: 1.4rem;
}
.table .table-body tr th,
.table .table-body tr td {
  border-bottom: none;
  font-family: "Open Sans", OpenSansFallback, sans-serif;
}
.table .table-body tr th:hover {
  cursor: pointer;
  text-decoration: underline;
}
.table .table-body tr td:last-child {
  text-align: right;
  overflow: visible;
}
.table .table-body tr td:last-child > ul {
  display: flex;
  justify-content: flex-end;
}
.table .table-body tr td:last-child button {
  height: 28px;
  background-color: white;
  color: #252626;
}
.table .table-body tbody tr:hover {
  cursor: pointer;
  background-color: #dceefa;
}
.table .table-footer {
  background-color: rgb(255, 255, 255);
  border-top: 1px solid #F7F7F7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.4rem 0 2rem;
  margin: 0;
}

.help-text ul {
  margin: 4px 8px;
  list-style-type: disc;
}
.help-text ul li {
  margin-left: 16px;
}
.help-text h3 {
  margin: 12px 0;
}
.help-text h4 {
  margin: 12px 0 4px 0;
}
.help-text p {
  margin: 8px;
}