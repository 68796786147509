.default-layout { 
  width: 100%;
  height: 100%;

  div.content {    
    width: 100%;   

    div.right-column {

      .right-content-container {
        min-width: 580px;
        padding: 20px;
      }

      .page-toolbar {
        padding: 1.4rem 0rem;
        display: flex;
        justify-content: flex-start;
        //margin-bottom: 5rem;
        color: #116FAE;
        font-size: 2rem;

        button {
          background-color: #4D8400;
          color: white;
          border: none;
          padding-top: 1.4rem;
          padding-bottom: 1.4rem;
          border-radius: 5px;
        }

        button:hover {
          cursor: pointer;
          background-color: #68A611;
        }
      }
    }
  }
}