* {
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;  
  font-family: "Open Sans", OpenSansFallback, sans-serif;
  // color: rgba(111, 101, 115, 1);
}

html,
body { 
  font-size: 10px;  
  background-color: #F7F7F7;
  scrollbar-width: thin;
}

app-root {
  font-size: 14px;
}

label {
  font-size: 1.4rem;
}

.busy-cursor {
  cursor: wait !important;
}

.list-items {  
  padding: $default-padding;  
}

.panel-header {
  font-size: 2.0rem; 
  font-weight: bold;
  margin-bottom: 16px;
}

.btn.btn-icon {
  padding: 0;
  border: none;
  cursor: pointer;  

  .vismaicon::before {
    background-color: rgb(73, 74, 74);
  }  
}

.btn.btn-icon:disabled {
  cursor: not-allowed;
  
  .vismaicon::before {
    background-color: #8A8A8A;
  }  
}

.details-table {
  margin-bottom: 16px;

  td {
    padding: 2px 6px;
  }
  .label, .value, select {
    font-size: 1.4rem !important;
  }
}

.nowrap {
  white-space: nowrap;
}

.pt-16 {
  padding-top: 16px !important;
}

.list-items tr:nth-child(odd) {
  background-color: #F7F7F7;
}

.list-items th {
  background-color: white !important;
  font-size: 14px;
}

#templateVersions {

  tr:nth-child(odd) {
    background-color: #fff;
  }

  tr:nth-child(odd):hover {
    background-color: #dceefa;
  } 

  tr:first-child {
    background-color: #F7F7F7;
  }
}

.centered {
  text-align: center !important;  
}

.centered .mat-sort-header-container {
  justify-content: center;
}

.full-width {
  width: 100%;
}

.vertical-middle {
  vertical-align: middle;
}

// #mergeFunctionDetails {
//   tr:first-child {
//     background-color: rgb(235, 231, 231);
//   }
// }

// #mergeFunctionFormats {
//   tr:first-child {
//     background-color: rgb(235, 231, 231);
//   }
// }

.hide-ribbonbar {
  #ribbonbar .tabs {
    display: none;
  }
}

.loaded {
  #ribbonbar {
    .tabs {
      display: block !important;
    }
  }
}

tr.mat-row:hover {
  background-color: yellow !important;
}

ul {
  list-style: none;
}

.paginator {
  display: flex;
  justify-content: center;
}

.hidden {
  visibility: hidden;
}

.displaynone {
  display: none !important;
}

.highlight {
  background-color: #FaD7A2 !important;
  transition: background-color 1s ease;
}

.mt-16 {
  margin-top: 16px;
}

.progressScreen {

  background-color: #ede5e5a8;
  position: fixed;
  top: 0%;
  left: 0%;
  // margin-top: -50px;
  // margin-left: -50px;
  width: 100vw;
  height: 100vh;
  // transform: translateX(-50%) translateY(-50%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4.2rem;
  transition: opacity linear 10s;
  backdrop-filter: blur(8px);
  //transition: z-index ease-in-out 10s;

  p {
    font-size: 1.4rem;
    text-align: center;
    transition: opacity 10s linear;
    animation: 1s ease-out sun-rise;
  }


  @keyframes sun-rise {
    from {
      /* pushes the sun down past the viewport */
      opacity: 0.0;
    }

    to {
      /* returns the sun to its default position */
      //transform: translateY(0);
      opacity: 1.0;
    }
  }

}

.primary-button {
  @extend .button;
  background-color: #2D7048;
  border-radius: 7px;
}

.wizard {

  flex: 1;
  overflow: hidden;

  .step-base {
    height: 100%;
    display: flex;
    flex-direction: column;
    animation: slide-from-right 1.5s ease-out;

    .title {
      margin-bottom: 2.8rem;

      p {
        white-space: nowrap;
      }

      p:last-of-type {
        font-size: 1.3rem;
        color: #64727c;
      }

      p:first-of-type {
        font-size: 1.4rem;
        color: #252626;
        font-weight: 700;
      }


    }

    .body {
      flex: 1;
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 1.4rem;

      button {
        margin-left: 14px;
      }
    }
  }

  .step {
    @extend .step-base;
  }

  .confirmation-step {
    @extend .step-base;

    .body {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    .messages {
      flex: 1;
      position: relative;

      .success-message,
      .error-message {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, 50%);
        padding: 0px;
        background-color: transparent;
        white-space: nowrap;
      }

      .error-message {
        color: #f63f06c2;
      }

      .success-message {
        color: #4d8400d1;
      }
    }
  }

  .summary-step {

    @extend .step-base;

    dl {

      dt {
        color: #72777a;
      }

      dd {
        background-color: #26375821 !important;
        padding: 5px 5px;
        margin-bottom: 1.4rem;
      }
    }

    table {
      
      td {
        padding: 0.7rem 1.4rem;
        //color: #263758;
        //border: 3px solid red;
      }

      td:first-of-type {
        background-color: #bac1c654;
        font-weight: 500;
        color: #778090;
      }

      td:last-of-type {
        background-color: #007aca36;
      }

      dd {
        background-color: #646a7645 !important;
        padding: 5px 5px;
        margin-bottom: 1.4rem;
      }
    }
  }

  .delete-step {
    @extend .step-base;

    .title {
      p:first-of-type {
        color: #ff5722;
      }
    }

    dl {

      dt {
        color: #72777a;
      }

      dd {
        background-color: #26375821 !important;
        padding: 5px 5px;
        margin-bottom: 1.4rem;
      }
    }
  }

  .placeholder-step {
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

mat-dialog-container {
  padding: 0px !important;
}



.user-messages {

  margin-top: 0.7rem;
  margin-bottom: 0.7rem;

  .message-base {
    padding: 1.4rem 0.7rem;
    border-radius: 4px;
  }

  .placeholder {
    @extend .message-base;
    visibility: hidden;
  }

  .error-message {
    @extend .message-base;
    background-color: #f33a241a;
    font-weight: 600;
    color: #f50303;
  }


  .warning-message {
    @extend .message-base;
    background-color: #ff880091;
  }

  .success-message {
    @extend .message-base;
    color: ghostwhite;
    background-color: #4d8400d1;
  }
}

input,
select,
textarea,
option {
  border: solid 1px #b1b7c4;
  padding: 3px;
  font-size: 1.4rem;
}

select {
  padding: 2px 0rem;
  outline: none;
}

.ribbon-group .ribbon-button-label-text {
  font-size: 12px;
}


mat-icon {
  font-family: "Material Icons" !important;

  &.button {
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  &.clear {
    color: black;
  }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.large-checkbox {
  width: 19px; 
  height: 19px;
  position: relative;
  top: 2px;
}

button {
  font-size: 1.4rem;
  border: 1px solid #c1c3c5;
  //border-radius: 4px;
  padding: 0 2.8rem;
  white-space: nowrap;
  height: 3.2rem;

  span {
    color: rgb(136, 135, 135);
    font-size: 1.12rem;
  }

  &:hover {
    span {
      color: black;
      transition: color ease 1s;
    }
  }

  &.primary {
    background-color: #4d8400;
    color: #eee;
    box-shadow: 1px 1px 5px 3px transparent;
    border-color: #4d8400;

    &:hover {
      color: white;
      border-color: #68a611;
      background-color: #68a611;
      box-shadow: 1px 1px 5px 3px rgb(104 166 17 / 20%);
      transition: background-color ease 1s;
    }
  }

  &.mat-button {
    border: 1px solid #c1c3c5;
    box-shadow: 0px 2px 2px rgba(22, 62, 89, 0.12);
    background-color: rgb(77, 132, 0);
    box-shadow: none;
    min-width: 100px;
    color: white;
    font-size: 16px;

    &:hover {
      background-color: rgb(144, 121, 148);
      box-shadow: 1px 1px 5px 3px #68a61133;
    }
  }

  &.has-icon {
    display: flex;
    align-items: center;
  }

  &[disabled] {
    color: #0000006b;
    cursor: default;
    background-color: #e7eae2;
    border: none;

    &:hover {
      color: #0000006b;
      background-color: #e7eae2;
      box-shadow: none;
      border: none;
    }
  }
}


.error-text {
  color: #f63f06c2;
}


.empty-table {
  background-color: white;
  text-align: center;
  height: 80px;
  line-height: 80px;
  margin-bottom: 1.4rem;
  color: #263758;
  display: hidden;
}

.primary-button {
  @extend .button;

  color: white;

  span {
    color: white;
  }

  //background-color: #4d8400;
  box-shadow: 1px 1px 5px 3px transparent;
  //border-color: #4d8400;

  &:hover {

    span {
      color: white;
    }

    border-color: #68a611;
    background-color: #68a611;
    box-shadow: 1px 1px 5px 3px rgb(104 166 17 / 20%);
    transition: background-color ease 1s;
  }
}

.optional-button {
  @extend .button;
  background-color: whitesmoke;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px transparent;
  border-radius: 7px;

  &:hover {
    color: #252626;
    border-color: #1482CC;
    background-color: #D2EAFA;
    // box-shadow: 1px 1px 5px 3px rgb(104 166 17 / 20%);
  }
}

.anchor-button {
  @extend .button;
  color: #7a798b;
  background-color: transparent;
  border: none;
  text-decoration-color: transparent;

  &:hover {
    color: #263758;
    text-decoration: underline;
    text-decoration-color: #263758;
    transition: color ease-out 1.0s, text-decoration-color ease-out 1.0s;
  }
}

.table-header-button {
  @extend .button;
  padding: 0.7rem 0.7rem;
  background-color: #4d8400;
  color: white;
  font-size: 1.4rem;
  display: flex;
  align-items: center;

  span {
    color: inherit;
  }

  mat-icon {
    height: 1.4rem;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  &:hover {
    color: #0060a1e6;
    background-color: #68a611;
    transition: background-color ease 1s;

    span,
    mat-icon {
      color: white;
    }
  }

}

.close-button {
  position: absolute !important;
  right: 0px;
  top: -3px;

  button {
    border: none;
    font-size: 2rem;
    line-height: 2rem;
    color: #252626;
    background: transparent;
  }

  &:hover {
    cursor: pointer;

    button {
      color: #263758;
    }
  }
}

.toolbar-button {

  @extend .button;

  display: flex;
  align-items: center;
  padding: 8px 20px;
  margin-left: 5px;

  mat-icon {
    font-size: 1.2rem;
    width: auto;
    height: auto;
  }
}

.toolbar-primary-button {
  @extend .toolbar-button;

  background-color: rgb(211, 206, 206);

  mat-icon,
  span {
    color: white;
  }

  mat-icon {
    font-size: 1.4rem;
    padding-right: 3px;
  }

  &:hover {
    background-color: #68a611;

    mat-icon,
    span {
      color: white;
    }
  }
}

.toolbar-optional-button {

  @extend .toolbar-button;

  background-color: whitesmoke;

  mat-icon,
  span {
    color: #7e8289;
  }

  mat-icon {
    font-size: 1.4rem;
    padding-right: 3px;
  }

  &:hover {
    background-color: #68a611;

    mat-icon,
    span {
      color: white;
    }
  }
}

.simple-row-optional-button {
  padding: 6px 25px;
  font-size: 1.4rem;
  background-color: #e0e4e747;
  color: #222b3c;
  border-color: #0c214824;

  &:hover {
    background-color: #007aca;
    color: white;
  }
}

//*:focus {
// border-color: red;
// border: solid 1px red;
//}

.button {

  &:hover {
    cursor: pointer;
  }

  &.default-button {
    @extend .button;
    background-color: green;
  }

  &.row-button {
    @extend .button;
    //background-color: white;
    width: 10vw;
  }

}

.dropDown {
  border-radius: 5px;
  //margin: 30px 70px 50px 0px;
  // box-shadow: 0px 2px 4px rgba(45, 45, 42, 0.2);
  width: 100%;
}

.dropDown:hover {
  cursor: pointer;
}

.label {
  margin-right: 20px;
}

.editButton {
  border-radius: 5px;
  padding: 0.42rem 2.8rem;
  background-color: white;
  z-index: 999;
}

.editButton:hover {
  cursor: pointer;
  border-color: #1482CC;
  background-color: #D2EAFA;
  //background-color: #316245;
}

.editButton3 {
  border-radius: 5px;
  padding: 0.42rem 2.8rem;
  color: white;
  background-color: #2D7048;
}

.editButton3:hover {
  cursor: pointer;
  background-color: #316245;
}

.baseTop {
  padding-bottom: 50px;
}

.list-items th {
  //color: #007ACA;
  font-weight: bold;
  //border-bottom: 1px solid red;
}

// tr {
//   //border-bottom: 1px solid red;
// }

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #007ACA !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #007ACA !important;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: #007ACA !important;
  --mdc-checkbox-selected-hover-icon-color: #007ACA !important;
  --mdc-checkbox-selected-icon-color: #007ACA !important;
}

.helpText {
  font-size: 1.7rem;
}

.closeHelp {
  float: right;
}


//custom editor dialogs
#butt {
  float: right;
}

#BackgroundDiv>div>div.txui-dialog-titlebar.txui-widget-header.txui-corner-all.txui-helper-clearfix {
  text-align: center;
  margin-left: 5px;
  background-color: #fbfbfb;
}

#OfficelinkModalContent {
  background-color: #fbfbfb;
  padding: 5px 5px 5px 5px;
  border: 1px solid #dddddd;
  border-top: none;

  // input,
  // select,
  // textarea,
  // option {
  //   border: none;
  // }
}

#selectedMergeField,
#selectedMergeFieldFormat {

  input,
  select,
  textarea,
  option {
    border: none;
  }
}

#OfficelinkModalContent>div.txui-dialog-buttonpane.txui-widget-content.txui-helper-clearfix>div {
  border-top: none;
}

// #mergeFieldDialog>fieldset:nth-child(2) {}

#mergeFieldDialog {
  //border: 0;
  width: auto;
  min-height: 61px;
  max-height: none;
  height: auto;
  background-color: #f0f0f0;
  padding: 10px 25px 10px 25px;
  border: none;
}

div.txui-dialog-content fieldset {
  border: 1px solid #dddddd !important;
}

.txui-widget-content {
  background-color: #fbfbfb;
  border: none !important;
}

#modalOkButton #modalCancelButton {
  float: right;
}

.txui-widget-header {
  border-width: 0 !important;
}

#OfficelinkModalContent>div.txui-dialog-buttonpane.txui-widget-content.txui-helper-clearfix>div {
  background-color: #f0f0f0 !important;
  padding: 0 23px 10px 550px;
  //float: right;
}

.mat-sort-header-content {
  font-weight: bold;
}

table>thead>tr {
  font-weight: bold;
}

.toolbar {
  box-shadow: #163e5914 0 4px 6px;
  z-index: 1000;
  width: 100%;
}

.center-panel {
  box-shadow: rgba(37, 38, 38, 0.12) 0px .1rem .1rem 0px;
  z-index: 1000;
}

.mergeFunctionDescription {
  display: flex;
}

.mergeFunctionLabels>p {
  padding-right: 4.2rem;
  padding-bottom: 1.4rem;
  font-weight: bold;
}

.mergeFunctionDescriptionValues p {
  padding-bottom: 1rem;
}

.huidigieVersie {
  font-weight: bold;
  font-size: 1.4rem;
}

.redirectBtn {
  font-size: 1.5rem;
  border: none;
  background-color: #F7F7F7;
  padding: 0;
  color: #007ACA;
}

.redirectBtn:hover {
  cursor: pointer;
  text-decoration: underline;
}

.redirectBtn2 {
  font-size: 1.5rem;
  border: none;
  background-color: #F7F7F7;
  padding: 0;
  color: black;
}

div.txui-dialog-content td,
div.txui-dialog-content th {
  background-color: #f0f0f0;
}

.vismaicon:not(.vismaicon-filled)::before {
  background-color: #8A8A8A;
}

.bread-crumb {
  font-size: 1.5rem;
  margin-bottom: 12px;
}

.dialog-close-button {
  width: 32px;
  height: 32px;
  margin-top: 8px;
}

td.text-max-100 {
  white-space: nowrap;        
  overflow: hidden;          
  text-overflow: ellipsis; 
  max-width: 100px;       
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

.flex-align-end {
  display: flex;
  justify-content: flex-end;
}

.width-42 {
  width: 42px;
}
.width-130 {
  width: 130px;
}
.width-200 {
  width: 200px;
}