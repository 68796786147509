.help-text {

    ul {   
        margin: 4px 8px;  
        list-style-type: disc;

        li {
            margin-left: 16px;
        }
    }

    h3 {
        margin: 12px 0;
    }

    h4 {
        margin: 12px 0 4px 0;
    }

    p {
        margin: 8px;
    }
}