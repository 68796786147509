.tm-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 24px !important;
}

.dialog-layout {
  @extend .layout;
  
  padding: 0px;
  position: relative;

  .left-panel,
  .right-panel {
    display: flex;
    flex-direction: column;
    padding: 4.2rem 4.2rem 4.2rem 4.2rem;
  }

  .left-panel {
    background-color: #007aca;
    justify-content: center;

    p {
      color: white;
    }

    .main-text {

      margin-top: auto;
      margin-bottom: auto;

      p {
        font-size: 4.2rem;
      }

    }

    .footer-text {
      p {
        font-size: 1.12rem;
        max-width: 10vw;
      }
    }
  }

  .right-panel {
    // background-color: #0c21480d;
    flex: 1;

    .close-button {
      // position: static;

      button {
        position: absolute;
        top: 0px;
        right: 5px;
        padding: 0px;
        cursor: pointer;
      }
    }

    form {
      flex: 1;
      display: flex;
    }

    .overview-table {
      thead {
        tr {

          td {
            background-color: #007aca;
            color: white;
            font-weight: 400;
          }
        }
      }


      tbody {
        td {
          padding: 0.35rem 1.4rem;
          background-color: #93a3d92e;
          color: #263758;
          font-weight: 300;

          button {
            padding: 5px 15px;
          }
        }

        td:first-of-type {
          font-weight: 600;
          background-color: #a5a6b13d;
        }

        td:last-of-type {
          button {
            width: 100%;

            color: #263758b8;

            &:hover {
              background-color: #4d8400;
              color: white;
            }
          }
        }
      }
    }
  }
}