.login-layout {

    width: 100vw;
    height: 100vh;

    .content {

        width: 30vw;
        margin: auto;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        padding: 1.4rem;

        input {
            margin-bottom: 20px;
            border: none;
            padding: 5px;
        }

        header {
            background-color: #CCBEBE;
            padding: 0.7rem 1.4rem;
        }

        main {
            display: flex;
            flex-direction: column;
            background-color: #CCBEBE;
            padding: 2.8rem 2.8rem;

    /*         button {
              background-color: #907994;
              border: none;
              padding: 10px;
              width: 5vw;
              color: white;
              margin-left: auto;
            } */
        }
    }



}


@media  (max-width: 480px) {


  .login-layout .content{
    width: 50vw;
    background-color: red !important;
  }
}


button{

}
